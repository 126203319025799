import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

import "./global.css";

import Entry from "./routes/entry";
import Frame from "./routes/frame";
import Meeting from "./routes/meeting";
import ThankYou from "./routes/thank-you";
import NotFound from "./routes/not-found";
import MeetingHost from "./routes/meeting-host";

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "connect",
  env: import.meta.env.VITE_ENVIRONMENT,
  version: import.meta.env.VITE_APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask",
});

datadogLogs.init({
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  service: "connect",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  env: import.meta.env.MODE,
  beforeSend: (log) => {
    if (log.message.includes("Error: the next main task")) {
      return false;
    }
    return true;
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Entry />,
  },
  {
    path: "/join/:shortId",
    element: <Meeting />,
  },
  {
    path: "/host/:shortId",
    element: <MeetingHost />,
  },
  {
    path: "/frame/:shortId/host",
    element: <Frame />,
  },
  {
    path: "/frame/:shortId/join",
    element: <Frame />,
  },
  {
    path: "/thank-you",
    element: <ThankYou />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

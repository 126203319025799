import { useEffect } from "react";
import { useParams } from "react-router-dom";
import * as zoom from "@/zoom";
import { getZoomSignature, ZoomSignature } from "@/api/getZoomSignature";
import { useSharedWorkerState } from "@/hooks/useSharedWorkerState";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

type SendSharedStateMessage = ReturnType<typeof useSharedWorkerState>[1];

enum FrameType {
  Join = "join",
  Host = "host",
}

function pollForBreakoutRoomInfo(
  sendSharedStateMessage: SendSharedStateMessage,
) {
  const pollInterval = 500; // 0.5 seconds
  let breakoutRoomId = "";

  async function getBreakoutRoomInfo() {
    try {
      const breakoutRoomData = await zoom.getBreakoutRoomInfo();

      if (!breakoutRoomData.status && breakoutRoomData.errorMessage) {
        new Error(breakoutRoomData.errorMessage);
      }

      // Handle setting the current breakout room
      if (
        breakoutRoomData.result.roomId !== breakoutRoomId &&
        (breakoutRoomData.result.attendeeStatus === "in room" ||
          breakoutRoomData.result.attendeeStatus === "return main session")
      ) {
        breakoutRoomId = breakoutRoomData.result.roomId;
        sendSharedStateMessage.setBreakoutRoomInfo(
          breakoutRoomData.result.roomId,
          breakoutRoomData.result.name,
        );
        autoEnableCaptions();
      }

      // Handle auto joining a breakout room
      if (breakoutRoomData.result.attendeeStatus === "be invited") {
        await zoom.joinBreakoutRoom(breakoutRoomData.result.roomId);
        autoEnableCaptions();
      }
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  setInterval(getBreakoutRoomInfo, pollInterval);
}

function pollForParticipantInfo(
  sendSharedStateMessage: SendSharedStateMessage,
) {
  const pollInterval = 5000; // 5 seconds

  async function getParticipantInfo() {
    try {
      const data = await zoom.getParticipants();

      if (!data.status && data.errorMessage) {
        new Error(data.errorMessage);
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!data.result.attendeesList) {
        datadogLogs.logger.error(
          `attendeesList result unexpected: ${JSON.stringify(data)}`,
        );
        new Error("Unexpected attendeesList missing in result");
      }

      sendSharedStateMessage.updateParticipantList(data.result.attendeesList);
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  setInterval(getParticipantInfo, pollInterval);
}

function listenToCaptions(sendSharedStateMessage: SendSharedStateMessage) {
  zoom.listenForCaptions((data) => {
    sendSharedStateMessage.addCaption(data);
  });
}

// Naively auto-enables captions since zoom sdk doesn't expose a programmatic way to enable them.
function autoEnableCaptions() {
  setTimeout(() => {
    const hideCaptionsBtn: HTMLElement | null = document.querySelector(
      'button[aria-label="Hide Captions"]',
    );
    if (hideCaptionsBtn) {
      // Skip entirely if captions already enabled
      return;
    }

    const showCaptionsBtn: HTMLElement | null = document.querySelector(
      'button[aria-label="Show Captions"]',
    );
    if (showCaptionsBtn) {
      // Standard "Show Captions" button
      showCaptionsBtn.click();
    } else {
      // Show Captions button flow for smaller screens
      const moreBtn: HTMLElement | null = document.querySelector(
        'button[aria-label="More meeting control"]',
      );
      moreBtn?.click();
      setTimeout(() => {
        const nestedCaptionsBtn: HTMLElement | null = document.querySelector(
          'a[aria-label="Captions"]',
        );
        nestedCaptionsBtn?.click();
        setTimeout(() => {
          const nestedShowCaptionsBtn: HTMLElement | null =
            document.querySelector(
              'a[aria-label="Your caption settings grouping Show Captions"]',
            );
          if (nestedShowCaptionsBtn) {
            nestedShowCaptionsBtn.click();
          } else {
            // Click on meeting controls bar if show captions on smaller screen already enabled to remove captions menu popup
            const meetingControls: HTMLElement | null =
              document.querySelector("#foot-bar");
            meetingControls?.click();
          }
        }, 250);
      }, 250);
    }

    // Accepts the language modal
    setTimeout(() => {
      for (const button of document.querySelectorAll("button.zm-btn")) {
        if (button instanceof HTMLElement && button.innerText === "Save") {
          button.click();
        }
      }
    }, 1000);
  }, 3000);
}

function keepRUMSessionAlive() {
  // Necessary to keep DD RUM sessions from timing out due to "inactivity"
  //   since the user doesn't interact with Connect often
  const pollInterval = 1000 * 60; // 1 minute

  function sendDatadogAction() {
    datadogRum.addAction("keepSessionAlive");
  }

  setInterval(sendDatadogAction, pollInterval);
}

interface BreakoutRoomParticipant {
  effectiveDisplayName: string;
  currentBreakoutRoomId: string;
  zoomParticipantId: number; // participantId and userId is the same value in the Zoom SDK participants object
  isNotetaker: boolean;
}

interface LostNotetaker {
  userId: number;
  targetRoomId: string;
}

/**
 * Synchronizes notetakers with their assigned Group Facilitators.
 * This function checks every 10 seconds to identify "lost" notetakers and ensures
 * they are in the same breakout room as their assigned Group Facilitators.
 *
 * A "lost" notetaker is defined as a user with the name "Notetaker for"
 * who is not currently in the same room as their assigned Group Facilitator.
 *
 * Note: Allow a few minutes for the host to open breakout rooms
 * and assign the notetaker as a co-host before this function runs.
 */
function syncNotetakers() {
  // Wait about 2 minutes for the host to open breakout rooms and set the notetaker as co-host before polling
  setTimeout(
    () => {
      // Intentionally empty
    },
    1000 * 60 * 2,
  ); // 2 minutes

  const pollInterval = 1000 * 60; // 60 seconds

  async function syncLostNotetakers() {
    try {
      // Check if current user is a co-host before proceeding
      const currentUser = await zoom.getCurrentUser();
      if (!currentUser.result.currentUser.isCoHost) {
        console.log(
          "RoomAssist is not a Co-host. Notetakers will not be synced.",
        );
        return;
      }

      // Fetch all participants by breakout room.
      const allBreakoutRoomParticipants: BreakoutRoomParticipant[] = [];
      const currentBreakoutRooms = await zoom.getBreakoutRooms();
      for (const room of currentBreakoutRooms.result.rooms) {
        for (const participant of room.participants) {
          allBreakoutRoomParticipants.push({
            effectiveDisplayName: participant.displayName.replace(
              /^Notetaker for\s*/,
              "",
            ), // Remove the prefix
            currentBreakoutRoomId: room.boId,
            zoomParticipantId: participant.participantId,
            isNotetaker: participant.displayName.startsWith("Notetaker for"),
          });
        }
      }

      // For each notetaker, check if they are in the same room as their assigned Group Facilitator
      const notetakers = allBreakoutRoomParticipants.filter(
        (participant) => participant.isNotetaker,
      );
      const lostNotetakersToMove: LostNotetaker[] = [];
      for (const notetaker of notetakers) {
        const groupFacilitator = allBreakoutRoomParticipants.find(
          (participant) =>
            participant.effectiveDisplayName ===
              notetaker.effectiveDisplayName && !participant.isNotetaker,
        );
        if (groupFacilitator) {
          // If notetaker has a matching GF, check if they are in the same room
          if (
            groupFacilitator.currentBreakoutRoomId !==
            notetaker.currentBreakoutRoomId
          ) {
            lostNotetakersToMove.push({
              userId: notetaker.zoomParticipantId,
              targetRoomId: groupFacilitator.currentBreakoutRoomId,
            });
          }
        } else {
          // If notetaker has no matching GF, expel them from the meeting
          console.log(
            `Found notetaker with no matching GF. Ejecting notetaker with userId ${notetaker.zoomParticipantId.toString()}`,
          );
          await zoom.expelFromMeeting(notetaker.zoomParticipantId);
        }
      }

      // Move lost notetakers to the same room as their assigned Group Facilitator
      for (const notetaker of lostNotetakersToMove) {
        console.log(
          `Found lost notetaker. Moving notetaker with userId ${notetaker.userId.toString()} to breakout room ${notetaker.targetRoomId}`,
        );
        await zoom.moveUserToBreakoutRoom(
          notetaker.userId,
          notetaker.targetRoomId,
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  setInterval(() => {
    void syncLostNotetakers();
  }, pollInterval);
}

async function startMeeting(
  joinInfo: ZoomSignature,
  sendSharedStateMessage: SendSharedStateMessage,
  meetingType: FrameType,
) {
  const displayName =
    meetingType === FrameType.Host
      ? joinInfo.displayName.replace(/^Notetaker for\s*/, "Room Assistant for")
      : joinInfo.displayName;

  sendSharedStateMessage.setUserInfo(displayName, joinInfo.email);

  await zoom.init(`/thank-you`);
  await zoom.joinMeeting(
    import.meta.env.VITE_ZOOM_SDK_KEY,
    joinInfo.meetingId,
    joinInfo.signature,
    displayName,
    joinInfo.email,
    joinInfo.tk,
    joinInfo.zak,
    joinInfo.passcode,
  );
  const meetingInfo = await zoom.getCurrentMeetingInfo();

  if (!meetingInfo.status && meetingInfo.errorMessage) {
    new Error(meetingInfo.errorMessage);
  }

  sendSharedStateMessage.setMeetingInfo(
    meetingInfo.result.meetingNumber.toString(),
    meetingInfo.result.meetingTopic,
    meetingInfo.result.mid,
  );

  if (meetingType === FrameType.Host) {
    // Host specific actions
    syncNotetakers();
  } else {
    // Join specific actions
    pollForBreakoutRoomInfo(sendSharedStateMessage);
    pollForParticipantInfo(sendSharedStateMessage);
    listenToCaptions(sendSharedStateMessage);
  }

  zoom.listenForStatusChanges();
}

function Frame() {
  const { shortId } = useParams();
  const isHost = location.pathname.includes("/host");
  const [, sendSharedStateMessage] = useSharedWorkerState();

  // Prepare the Zoom Meeting SDK once on component mount
  useEffect(() => {
    zoom.preLoadWasm();
    zoom.prepareWebSDK();
    keepRUMSessionAlive();
  }, []);

  // Fetch the join info for the meeting when the shortId changes
  useEffect(() => {
    async function getJoinInfo() {
      if (shortId) {
        try {
          const result = await getZoomSignature(shortId);
          if (result.jwt) {
            sendSharedStateMessage.setToken(result.jwt);
            const meetingType = isHost ? FrameType.Host : FrameType.Join;
            await startMeeting(result, sendSharedStateMessage, meetingType);
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    void getJoinInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortId]);

  return null;
}

export default Frame;

import { useParams } from "react-router-dom";
import { memo, useEffect } from "react";
import { Link } from "@/components/catalyst/link";
import {
  Sidebar,
  SidebarSection,
  SidebarFooter,
} from "@/components/catalyst/sidebar";
import { Text } from "@/components/catalyst/text";
import { SidebarLayout } from "@/components/catalyst/sidebar-layout";
import { datadogRum } from "@datadog/browser-rum";

const MeetingIFrame = memo(function MeetingIFrame(
  props: React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  >,
) {
  return (
    <iframe
      src={props.src}
      allow="camera; microphone; display-capture; autoplay"
      className="flex-1 h-full w-full min-h-[calc(100vh-6rem)]"
    ></iframe>
  );
});

const stopRUMSession = () => {
  datadogRum.stopSession();
  return false;
};

function MeetingHost() {
  const { shortId } = useParams();

  useEffect(() => {
    window.addEventListener("beforeunload", stopRUMSession);

    return () => {
      window.removeEventListener("beforeunload", stopRUMSession);
    };
  }, []);

  if (shortId) {
    return (
      <SidebarLayout
        sidebar={
          <Sidebar className="h-dvh flex">
            <div className="flex-1 overflow-y-scroll">
              <SidebarSection />
              <div>
                <Text>
                  The Connect sidebar is under active development. We will be
                  adding features here throughout the Alpha and Beta testing
                  periods. Have thoughts on what insights or information we
                  should include? Share them with us
                  <Link
                    className="underline"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdCv3CEC9cOOH1cFi2VBBWKdLbaFqg-nvABq6olfPXXcxVpLA/viewform"
                    target="_blank"
                  >
                    here
                  </Link>
                </Text>
              </div>
              <SidebarSection />
            </div>
            <SidebarFooter>
              <Text>Version: {import.meta.env.VITE_APP_VERSION_SHORT}</Text>
            </SidebarFooter>
          </Sidebar>
        }
        navbar={<></>}
      >
        <MeetingIFrame src={`/frame/${shortId}/host`} />
      </SidebarLayout>
    );
  } else {
    return <></>;
  }
}

export default MeetingHost;
